import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const englishData = {
    section1: {
        title1: 'Sauraurja ',
        title2: " Co-Operative Organization",
        text: 'Brings clean energy independence and cost reduction to villages with a solar  power plant and battery storage system.'
    },
    section2: {
        text: "Project Everest offers a sustainable future for rural communities. Villages gain energy independence with a solar power plant and battery storage, reducing electricity costs and  environmental impact. The project creates jobs, promotes efficient power management,",
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'Goals',
            points: ["Equip rural communities with long-term access to sustainable and reliable power, fostering development and progress.", ]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Benefits',
            text: [
                "Reduced Electricity Bills: Generate clean solar power, reducing dependence on the grid and potentially lowering costs.",
                "Enhanced Power Stability: Battery storage ensures consistent and reliable electricity supply for villages.",
                "Employment Opportunities: Create new jobs in rural areas for project installation  and maintenance.",
                "Reduced Environmental Impact: Harness clean solar energy to minimize reliance on fossil fuels.",
                "Demand-Side Management: Empower villagers to optimize energy use and reduce strain on the power grid.",
                "Subsidy Reduction for Government: Lower overall project cost per KW leads to a lower subsidy burden."

            ]
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "Bank's Growth with Tejas Patel (Leading with Vision, Delivering Progress.)",
            text: [
                "Centralized Monitoring: Closely monitor the system for swift fault identification and  rectification.",
                "Single Bidirectional Meter: Eliminate the need for multiple meters, simplifying  infrastructure.",
                "Advanced Technology: Leverage advanced tech for easier operation and  maintenance.",
                "Scalable Solution: The project can be scaled to meet growing energy demands of villages.",
                "Sustainable Materials: Utilize high-quality, long-lasting materials for durability.",
                "Community Ownership: Empower villages through ownership and participation in the project."

            ]
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'Transformation',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'Visionary Leadership',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'Innovation',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'Customer Centricity',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'Efficiency',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'Community Focus',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'Impact and Benefits',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '665 Kwp',
                text: 'Total Solar Plant Capacity'
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: 'Rs. 2,66,00,000',
                text: 'Total Solar Plant Cost'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '40,000/Kwp',
                text: 'Average Solar Plant Cost'
            },
            // {
            //     img: `${require('../images/benefit4.png')}`,
            //     title: '200+ Distributors',
            //     text: 'Distributors across India'
            // },
            // {
            //     img: `${require('../images/benefit5.png')}`,
            //     title: '400+ Dealers',
            //     text: 'Lorem Ipsum sit'
            // },
            // {
            //     img: `${require('../images/benefit6.png')}`,
            //     title: '1 million +',
            //     text: 'Happy Farmers'
            // },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'Conclusion',
        text: "Solar Project is a Beacon of hope for a sustainable future. By harnessing clean solar energy, we empower villages to achieve energy security, reduce costs, and create a positive  environmental impact. This innovative project paves the way for a brighter future for rural  communities, fostering development and progress.",
        button1: 'Donate',
        button2: 'Volunteer'
    }
}

const gujratData = {
    section1: {
        title1: 'સૌર ઉર્જા સહકારી સંસ્થા ',
        title2: "ટिकाઉ ઊર્જા દ્વારા ગામોને સશક્તિકરણ",
        text: 'સૌર ઉર્જા સહકારી સંસ્થા ગામોને સોલાર પાવર પ્લાન્ટ અને બેટરી સ્ટોરેજ સિસ્ટમ દ્વારા સ્વચ્છ ઊર્જા સ્વતંત્રતા અને  ખર્ચ ઘટાડો લાવે છે.'
    },
    section2: {
        text: "સૌર ઉર્જા સહકારી સંસ્થા ગ્રામીણ સમુદાયો માટે ટिकाઉ ભવિષ્ય પ્રદાન કરે છે. ગામો સોલાર પાવર પ્લાન્ટ અને  બેટરી સ્ટોરેજ સાથે ઊર્જા સ્વતંત્રતા મેળવે છે, જેનાથી વીજળીના ખર્ચ અને પર્યાવરણીય અસર ઘટાડે છે. આ પ્રોજેક્ટ  રોજગારી ઉત્પન્ન કરે છે, કાર્યક્ષમ વીજળી વ્યવસ્થાપનને પ્રોત્સાહન આપે છે અને વિશ્વસનીય અને સ્કેલેબલ સોલ્યુશન માટે અદ્યતન ટેકનોલોજીનો ઉપયોગ કરે છે.",
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'લક્ષ્યો',
            points: ["ગ્રામીણ સમુદાયોને ટકાઉ અને ભરોસાપાત્ર શક્તિ સુધી લાંબા ગાળાની પહોંચ સાથે સજ્જ કરો, વિકાસ અને પ્રગતિને પ્રોત્સાહન આપવો"]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "લાભો",
            text: [
             "સ્વચ્છ સૌર ઉર્જા ઉત્પન્ન કરો, ગ્રીડ પરની અવલંબન ઘટાડીને અને સંભવિત રીતે ખર્ચમાં ઘટાડો કરો.",
             "બેટરી સ્ટોરેજ ગામડાઓ માટે સતત અને ભરોસાપાત્ર વીજ પુરવઠો સુનિશ્ચિત કરે છે.",
             "પ્રોજેક્ટની સ્થાપના અને જાળવણી માટે ગ્રામીણ વિસ્તારોમાં નવી નોકરીઓનું સર્જન કરે છે.",
             "અશ્મિભૂત ઇંધણ પર નિર્ભરતા ઘટાડવા માટે સ્વચ્છ સૌર ઊર્જાનો ઉપયોગ કરાવે છે.",
             "ઉર્જાનો ઉપયોગ ઑપ્ટિમાઇઝ કરવા અને પાવર ગ્રીડ પરનો તાણ ઘટાડવા માટે ગ્રામજનોને સશક્ત બનાવે છે.",
             "એકંદર પ્રોજેક્ટ ખર્ચ પ્રતિ કેડબલ્યુ ઓછો થવાથી સબસિડીનો બોજ ઓછો થાય છે.", 
            ]
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: "વિશેષતાઓ",
            text: [
            "સેન્ટ્રલાઈઝ્ડ મોનિટરિંગ: ઝડપથી ખામીની ઓળખ અને સુધારણા માટે સિસ્ટમનું નજીકથી નિરીક્ષણ કરાવ્યું.",
            "સિંગલ બાયડાયરેક્શનલ મીટર: ઈન્ફ્રાસ્ટ્રક્ચરને સરળ બનાવીને બહુવિધ મીટરની જરૂરિયાતને દૂર કરે છે.",
            "અદ્યતન ટેકનોલોજી: સરળ કામગીરી અને જાળવણી માટે અદ્યતન તકનીકનો લાભ આપ્યો.",
            "સ્કેલેબલ સોલ્યુશન: ગામડાઓની વધતી જતી ઉર્જાની માંગને પહોંચી વળવા પ્રોજેક્ટને માપી શકાય છે.",
            "ટકાઉ સામગ્રી: ટકાઉપણું માટે ઉચ્ચ ગુણવત્તાવાળી, લાંબા સમય સુધી ચાલતી સામગ્રીનો ઉપયોગ કરાવે છે.",
            "સામુદાયિક માલિકી: પ્રોજેક્ટમાં માલિકી અને ભાગીદારી દ્વારા ગામડાઓને સશક્ત બનાવે છે.",

            ]
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'પરિવર્તન',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'વિઝનરી લીડરશીપ',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'નવીનતા',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'ગ્રાહક કેન્દ્રિતતા',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'કાર્યક્ષમતા',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'સમુદાય ફોકસ',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'અસર અને લાભો',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: ' 665 Kwp',
                text: 'કુલ સોલાર પ્લાન્ટ કેપેસિટી '
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: 'રૂ. 2,66,00,000',
                text: 'કુલ સોલાર પ્લાન્ટની કિંમત'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: ' રૂ. 40,000/Kwp',
                text: 'સરેરાશ સોલાર પ્લાન્ટ કિંમત'
            },
            // {
            //     img: `${require('../images/benefit4.png')}`,
            //     title: '200+ Distributors',
            //     text: 'Distributors across India'
            // },
            // {
            //     img: `${require('../images/benefit5.png')}`,
            //     title: '400+ Dealers',
            //     text: 'Lorem Ipsum sit'
            // },
            // {
            //     img: `${require('../images/benefit6.png')}`,
            //     title: '1 million +',
            //     text: 'Happy Farmers'
            // },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'નિષ્કર્ષ',
        text: "સોલાર પ્રોજેક્ટ ટકાઉ ભવિષ્ય માટે આશાનું કિરણ છે. સ્વચ્છ સૌર ઉર્જાનો ઉપયોગ કરીને, અમે ગામડાઓને ઉર્જા સુરક્ષા પ્રાપ્ત કરવા, ખર્ચ ઘટાડવા અને સકારાત્મક પર્યાવરણીય અસર બનાવવા માટે સશક્ત બનાવીએ છીએ. આ નવીન પ્રોજેક્ટ ગ્રામીણ  સમુદાયો માટે ઉજ્જવળ ભવિષ્યનો માર્ગ મોકળો કરે છે, વિકાસ અને પ્રગતિને પ્રોત્સાહન આપે છે.",
        button1: 'દાન કરો',
        button2: 'સ્વયંસેવક'
    }
}




const Sauraurja = () => {
    const { language } = useLanguage();

    const [projectDetailData, setProjectDetailData] = useState()

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        if (language) {
            setProjectDetailData(englishData)
        } else {
            setProjectDetailData(gujratData)
        }
    }, [language])


    return (
        <div style={{ overflowX: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='projectDetailTopSouraurja' >
                <div class='col-lg-4 col-md-6 col-10 text-start ' style={{ position: 'absolute', bottom: '5%', overflow: 'hidden' }}>
                    <div class='col-12' style={{ background: '#EA5136' }} data-aos="fade-right">
                        <p class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title1}</p>
                    </div>
                    <p data-aos="fade-right" class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title2}</p>

                    <div class='col-10 text-start mt-3 text-white' style={{ marginLeft: '10%' }} data-aos="fade-right">
                        <p class='fs-5'>{projectDetailData?.section1?.text}</p>
                    </div>
                </div>
            </div>

            <div class='col-11 row text-start m-auto my-5'>
                <div class='col-md-7 mt-3'>
                    <div class='col-lg-8' data-aos="fade-right">
                        <p class='fs-3'>{projectDetailData?.section2?.text}</p>
                    </div>
                </div>
                <div class='col-md-5'>
                    {/* <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-calendar2-fill mx-1"></i></span> <span>{projectDetailData?.section2?.timeLine?.title}</span></p>
                        <p class='mx-4'>{projectDetailData?.section2?.timeLine?.weeks}</p>
                    </div> */}
                    <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-rocket-takeoff-fill mx-1"></i></span> <span>{projectDetailData?.section2?.goals?.title}</span></p>
                        <ul>
                            {
                                projectDetailData?.section2?.goals?.points?.map((el) => {
                                    return (

                                        <li class=''>{el}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>


            </div>

            <div class='row'>
                {
                    projectDetailData?.section3?.map((el) => {
                        return (
                            <div class='col-md-6 text-start' data-aos="fade-right" style={{ background: `${el.background}` }}>
                                <div class='col-11 m-auto text-white'>
                                    <div class='my-4'>
                                        <img src={el.icon} style={{ width: '36px', height: '36px' }} alt="" />
                                    </div>
                                    <p class='fs-4 fw-semibold my-4'>{el.title}</p>
                                    <ul>

                                        {el?.text?.map((el) => {
                                            return (
                                                <li>{el}</li>
                                            )
                                        })}
                                    </ul>
                                    <br />
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            {/* <div class='col-lg-10 col-11 m-auto my-5' data-aos="zoom-in">
                <p class='tJustify'>{projectDetailData?.section4?.text}</p>
            </div> */}

            {/* <div class='row justify-content-end m-0'>
                <div class='col-md-11 rounded-5 row justify-content-end p-0' style={{ backgroundImage: `url(${projectDetailData?.section4?.imgbg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                    <div class='col-md-6 col-sm-8 col-11 text-end d-flex justify-content-end text-black' data-aos="fade-left" style={{ background: '#FBF7A9', clipPath: 'inset(0 0 0 0 round 150px 0 0 150px)' }}>
                        <div class='my-5 col-11 p-2'>
                            <p class='fs-3 fw-bold text-black'>{projectDetailData?.section4?.title}</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class='benifitsUp'>

                <div class='benifitsUpPoint1' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point1Img} style={{ width: '120px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point1}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint2' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point2Img} style={{ width: '35px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point2}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint3' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point3Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point3}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint4' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point4Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point4}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint5' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point5Img} style={{ width: '20px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point5}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint6' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point6Img} style={{ width: '100px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point6}</p>
                    </div>
                </div>
            </div>
            <br />

            <div style={{ background: '#727CB6', color: 'white' }}>
                <br />
                <p class='h1 my-3' data-aos="fade-down">{projectDetailData?.section6?.title}</p>
                {/* <p class='col-lg-4 col-md-6 col-11 m-auto' data-aos="fade-down">{projectDetailData?.section6?.text}</p> */}
                <div class='col-11 row justify-content-center m-auto my-4'>
                    {
                        projectDetailData?.section6?.cart?.map((el) => {
                            return (
                                <div class='col-lg-4 col-md-6 my-3' data-aos="fade-up">
                                    <div class='col-11 m-auto rounded-4  h-100 p-2' style={{ backgroundImage: 'linear-gradient( rgba(65,72,116,0.9), rgba(65,72,116,0.1))' }}>
                                        <img class='mt-3' src={el.img} style={{ width: '60px' }} alt="" />
                                        <p class='fs-5 fw-bold my-3'>{el.title}</p>
                                        <p class='my-3'>{el.text}</p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <br />

            </div>

            <div class=' row flex-column-reverse flex-md-row '>
                <div class='col-md-6'>
                    <div class='col-sm-10 col-11 m-auto text-start my-4'>
                        <p class='fs-1 fw-bold' data-aos="fade-right" style={{ color: '#EA5136' }}>{projectDetailData?.section7?.title}</p>
                        <p class='my-2 fs-5' data-aos="fade-right">{projectDetailData?.section7?.text}</p>
                        <div class='mt-3' data-aos="fade-right">
                            <button class="custom-btn btnBlue  "><span>{projectDetailData?.section7?.button1}</span></button>
                            <button class="custom-btn btnOrange"><span>{projectDetailData?.section7?.button2}</span></button>
                        </div>
                    </div>
                </div>
                <div class='col-md-6' data-aos="zoom-in">
                    <img src={projectDetailData?.section7?.img} style={{ width: '100%', minHeight: '300px' }} alt="" />
                </div>
            </div>


        </div>
    )
}

export default Sauraurja